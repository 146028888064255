import React from "react"
import Layout from './../components/shared/Layout';
import SectionArticles from './../components/section/SectionArticles';

const RestaurantPage = () => (
  <Layout title="Restaurant Reviews">
      <SectionArticles section="Restaurant" description="Cafe Living Restaurant Reviews" title="Restaurant Reviews"/>
  </Layout>
)

export default RestaurantPage;
